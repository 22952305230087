import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import VerifyLawyer from './components/VerifyLawyer';
import SignIn from './components/SignIn';
import Dashboard from './components/Dashboard';
import LayoutWrapper from './components/LayoutWrapper';
import Users from './components/Users';
import Projects from './components/Projects';
import Documents from './components/Documents';

export default function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route
            path="/documents"
            element={
              <LayoutWrapper>
                <Documents />
              </LayoutWrapper>
            }
          />
          {/*    <Route
            path="/verify-lawyers/:id"
            element={
              <LayoutWrapper>
                <VerifyLawyer />
              </LayoutWrapper>
            }
          /> */}
          <Route
            path="/"
            exact
            element={
              <LayoutWrapper>
                <Dashboard />
              </LayoutWrapper>
            }
          />
          <Route
            path="/projects"
            element={
              <LayoutWrapper>
                <Projects />
              </LayoutWrapper>
            }
          />
          <Route
            path="/users"
            element={
              <LayoutWrapper>
                <Users />
              </LayoutWrapper>
            }
          />
          <Route
            path="/login"
            element={
              <LayoutWrapper>
                <SignIn />
              </LayoutWrapper>
            }
          />
        </Routes>
      </div>
    </Router>
  );
}
