import axios from 'axios';
import { token } from '../components/helpers/utils';
import { toast } from 'react-toastify';

export const axiosUser = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/`,
  timeout: 5000,
});

axiosUser.interceptors.request.use(function (confiq) {
  confiq.headers['Authorization'] = `Bearer ${token()}`;
  return confiq;
});

// Add a response interceptor
axiosUser.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    //console.log('error', error.response.data);
    toast.error(error || error.response.data.message || 'Something went wrong');
    //Router.push('/');
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);
