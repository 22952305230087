import { createContext, useState } from 'react';
import {
  getUserFromToken,
  isValidTokenExists,
} from '../components/helpers/utils';

export const UserContext = createContext();

const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState({
    isLoggedIn: isValidTokenExists(),
    token: '',
    firstName: '',
    lastName: '',
    email: '',
    currentUser: getUserFromToken(),
  });

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
