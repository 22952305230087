import MaterialTable from '@material-table/core';
import { useState } from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { axiosUser } from '../managers/AxiosInstance';

const Users = () => {
  const [users, setUsers] = useState([]);
  useEffect(() => {
    const getUsers = async () => {
      await axiosUser
        .get(`${process.env.REACT_APP_API_URL}cms-users`)
        .then((res) => {
          setUsers(res.data.users);
        });
    };
    getUsers();
  }, []);

  return (
    <MaterialTable
      title="Users"
      data={users}
      columns={[
        {
          title: 'ID',
          field: '_id',
          cellStyle: {
            fontSize: '12px',
          },
          /*  render: (rowData) => (
            <Link to={`/users/${rowData._id}`}>{rowData._id}</Link>
          ), */
          /**
           * Hide filter on specific column
           */
          filtering: false,
        },
        {
          title: 'Name',
          field: 'firstName',
          /**
           * Hide filter on specific column
           */
        },
        {
          title: 'Surname',
          field: 'lastName',
        },
        {
          title: 'Email',
          field: 'email',
        },
        {
          title: 'Role',
          field: 'role',
          cellStyle: (e, rowData) => {
            if (rowData.role == 'client') {
              return { color: 'blue' };
            } else {
              return { color: 'orange' };
            }
          },
        },
        {
          title: 'Activated',
          field: 'activated',
          lookup: {
            true: 'Active',
            false: 'Inactive',
          },
          cellStyle: (e, rowData) => {
            if (rowData.activated) {
              return { color: 'green' };
            } else {
              return { color: 'red' };
            }
          },
        },
        {
          title: 'Documents Verified',
          field: 'documentsVerified',
          lookup: {
            true: 'Verified',
            false: 'Unverified',
          },
          cellStyle: (e, rowData) => {
            if (rowData.documentsVerified) {
              return { color: 'green' };
            } else {
              return { color: 'red' };
            }
          },
        },
      ]}
      options={{
        filtering: true,
      }}
    />
  );
};

export default Users;
