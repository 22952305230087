import MaterialTable from '@material-table/core';
import { Box, Button, Modal, Typography } from '@mui/material';
import { useState } from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { axiosUser } from '../managers/AxiosInstance';
import { ToastContainer, toast } from 'react-toastify';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const Documents = () => {
  const [documents, setDocuments] = useState([]),
    [id, setId] = useState(''),
    [isApprove, setIsApprove] = useState(false),
    [refetch, setIsRefetch] = useState(false),
    [open, setOpen] = useState(false);

  useEffect(() => {
    //setIsRefetch(false);
    const getDocuments = async () => {
      await axiosUser
        .get(`${process.env.REACT_APP_API_URL}cms-documents`)
        .then((res) => {
          setDocuments(res.data.documents);
        });
    };
    getDocuments();
  }, [refetch]);

  const approveHandler = (id) => {
    setId(id);
    setIsApprove(true);
    setOpen(true);
  };

  const rejectHandler = (id) => {
    setId(id);
    setIsApprove(false);
    setOpen(true);
  };

  const approveDocument = async () => {
    await axiosUser
      .post(`${process.env.REACT_APP_API_URL}document/approve`, {
        id: id,
      })
      .then((res) => {
        setOpen(false);
        setIsApprove(false);
        setId('');
        setIsRefetch((prevRefetch) => !prevRefetch);
      });
  };

  const rejectDocument = async () => {
    await axiosUser
      .post(`${process.env.REACT_APP_API_URL}document/reject`, {
        id: id,
      })
      .then((res) => {
        setOpen(false);
        setIsApprove(false);
        setId('');
        setIsRefetch((prevRefetch) => !prevRefetch);
      });
  };

  const handleClose = () => {
    setId('');
    setIsApprove(false);
    setOpen(false);
  };

  return (
    <>
      <MaterialTable
        title="Documents"
        data={documents}
        columns={[
          {
            title: 'ID',
            field: '_id',
            cellStyle: {
              fontSize: '12px',
            },
            /*  render: (rowData) => (
            <Link to={`/users/${rowData._id}`}>{rowData._id}</Link>
          ), */
            /**
             * Hide filter on specific column
             */
            filtering: false,
          },
          {
            title: 'Lawyer Id',
            field: 'userId._id',
            cellStyle: {
              fontSize: '12px',
            },
            /**
             * Hide filter on specific column
             */
          },
          {
            title: 'Name',
            field: 'userId.firstName',
          },
          {
            title: 'Surname',
            field: 'userId.lastName',
          },
          {
            title: 'Email Activated',
            field: 'userId.activated',
            cellStyle: (e, rowData) => {
              if (rowData.userId.activated) {
                return { color: 'green' };
              } else {
                return { color: 'red' };
              }
            },
            render: (rowData) => {
              return rowData.userId.activated ? 'Yes' : 'No';
            },
          },
          {
            title: 'Documents Approved',
            field: 'userId.documentsVerified',
            cellStyle: (e, rowData) => {
              if (rowData.userId.documentsVerified) {
                return { color: 'green' };
              } else {
                return { color: 'red' };
              }
            },
            render: (rowData) => {
              return rowData.userId.documentsVerified ? 'Yes' : 'No';
            },
          },
          {
            title: 'Uploaded At',
            field: 'createdAt',
          },
          {
            title: 'Document Type',
            field: 'type',
          },
          {
            title: 'Document Link',
            field: 'file',
            render: (rowData) => (
              <a
                href={`${process.env.REACT_APP_UPLOADS_URL}${rowData.file}`}
                target="_blank"
              >
                View
              </a>
            ),
          },
          {
            title: 'Status',
            field: 'status',
            cellStyle: (e, rowData) => {
              if (rowData.status === 'APPROVED') {
                return { color: 'green' };
              } else if (rowData.status === 'REJECTED') {
                return { color: 'red' };
              }
            },
            lookup: {
              APPROVED: 'Aprroved',
              REJECTED: 'Rejected',
              PENDING: 'Pending',
            },
          },
          {
            title: 'Approve',
            render: (rowData) => {
              if (rowData.status == 'PENDING') {
                return (
                  <Button
                    variant="outlined"
                    color="success"
                    onClick={() => approveHandler(rowData._id)}
                  >
                    Approve
                  </Button>
                );
              }
            },
          },
          {
            title: 'Reject',
            render: (rowData) => {
              if (rowData.status == 'PENDING') {
                return (
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => rejectHandler(rowData._id)}
                  >
                    Reject
                  </Button>
                );
              }
            },
          },
        ]}
        options={{
          filtering: true,
        }}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {isApprove ? (
            <>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Approve Document
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Are you sure want to approve this document?
              </Typography>
              <Button
                sx={{ my: 3 }}
                variant="contained"
                color="success"
                onClick={approveDocument}
              >
                Yes, Approve!
              </Button>
            </>
          ) : (
            <>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Reject Document
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Are you sure want to reject this document?
              </Typography>
              <Button
                sx={{ my: 3 }}
                variant="contained"
                color="error"
                onClick={rejectDocument}
              >
                Yes, Reject!
              </Button>
            </>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default Documents;
