import jwtDecode from 'jwt-decode';
import LocalStorageManager from '../../managers/LocalStorageManager';

export const getUserFromToken = () => {
  if (typeof window !== 'undefined') {
    const user = LocalStorageManager.getItem('user');
    if (user) {
      return { ...user };
    }
  }
  return {};
};

export const token = () => {
  if (typeof window !== 'undefined') {
    const token = LocalStorageManager.getItem('token');
    return token;
  }
};

export const isValidTokenExists = () => {
  if (typeof window !== 'undefined') {
    const token = LocalStorageManager.getItem('token');
    if (token) {
      const decoded = jwtDecode(token);
      if (decoded.user.role != 'admin') {
        return false;
      } else {
        return new Date() < new Date(jwtDecode(token).exp * 1000);
      }
    }
  }
  return false;
};
