import { Box, Button, FormControl, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { useEffect } from 'react';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';
import LocalStorageManager from '../managers/LocalStorageManager';
import { isValidTokenExists } from './helpers/utils';

const SignIn = () => {
  const [email, setEmail] = useState(''),
    [password, setPassword] = useState('');
  const { user, setUser } = useContext(UserContext),
    isLoggedin = isValidTokenExists(),
    navigate = useNavigate();
  console.log('user', user);

  useEffect(() => {
    console.log('yooo');
    if (isLoggedin) {
      console.log('yooo');
      navigate('/');
    }
  }, [isLoggedin]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('submitteed');
    await axios
      .post(`${process.env.REACT_APP_API_URL}admin-login`, {
        email,
        password,
      })
      .then((res) => {
        const { token } = res.data;
        let temp = res.data.user;
        let newUser = { ...user, ...temp };
        LocalStorageManager.setItem('token', token);
        LocalStorageManager.setItem('user', JSON.stringify(user));
        setUser(newUser);
      })
      .catch((err) => console.log('err', err));
  };

  return (
    <>
      <Box
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ mx: 'auto', width: 600, mt: 8 }}
        xs={5}
      >
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Typography
            variant="h3"
            gutterBottom
            direction="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
          >
            KANON Admin
          </Typography>
          <FormControl
            style={{ backgroundColor: '#fff', width: 600, padding: 50 }}
          >
            <TextField
              onChange={(e) => setEmail(e.target.value)}
              id="outlined-email-input"
              label="email"
              type="email"
              style={{ marginTop: 50, backgroundColor: '#fff' }}
            />
            <TextField
              onChange={(e) => setPassword(e.target.value)}
              id="outlined-password-input"
              label="Password"
              type="password"
              style={{ marginTop: 50, backgroundColor: '#fff' }}
            />
            <Button
              type="submit"
              style={{ marginTop: 50 }}
              variant="outlined"
              color="secondary"
              disabled={!email || !password}
            >
              Login
            </Button>
          </FormControl>
        </form>
      </Box>
    </>
  );
};

export default SignIn;
