import UserContextProvider from '../contexts/UserContext';
import Layout from './Layout';

const LayoutWrapper = ({ children }) => {
  return (
    <UserContextProvider>
      <Layout>{children}</Layout>
    </UserContextProvider>
  );
};

export default LayoutWrapper;
