import MaterialTable from '@material-table/core';
import { useState } from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { axiosUser } from '../managers/AxiosInstance';

const Projects = () => {
  const [projects, setProjects] = useState([]);
  useEffect(() => {
    const getProjects = async () => {
      await axiosUser
        .get(`${process.env.REACT_APP_API_URL}cms-projects`)
        .then((res) => {
          setProjects(res.data.projects);
        });
    };
    getProjects();
  }, []);

  return (
    <MaterialTable
      title="Projects"
      data={projects}
      columns={[
        {
          title: 'ID',
          field: '_id',
          cellStyle: {
            fontSize: '12px',
          },
          /*  render: (rowData) => (
            <Link to={`/users/${rowData._id}`}>{rowData._id}</Link>
          ), */
          /**
           * Hide filter on specific column
           */
          filtering: false,
        },
        {
          title: 'Title',
          field: 'title',
          /**
           * Hide filter on specific column
           */
        },
        {
          title: 'Description',
          field: 'description',
        },
        {
          title: 'Client ID',
          field: 'userId',
          cellStyle: {
            fontSize: '12px',
          },
        },
        {
          title: 'Fixed Rate',
          field: 'fixedRate',
        },
        {
          title: 'Hourly From',
          field: 'hourlyFrom',
        },
        {
          title: 'Hourly To',
          field: 'hourlyTo',
        },
      ]}
      options={{
        filtering: true,
      }}
    />
  );
};

export default Projects;
