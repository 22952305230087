import { useEffect } from 'react';
import { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import UserContextProvider, { UserContext } from '../contexts/UserContext';
import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LocalStorageManager from '../managers/LocalStorageManager';
import { isValidTokenExists } from './helpers/utils';
const drawerWidth = 240;

const Layout = ({ children }) => {
  const { user, setUser } = useContext(UserContext),
    isLoggedIn = isValidTokenExists(),
    location = useLocation(),
    navigate = useNavigate();

  const logout = () => {
    LocalStorageManager.removeItem('token');
    LocalStorageManager.removeItem('user');
    navigate('/login');
  };

  useEffect(() => {
    if (isLoggedIn && location.pathname == '/login') {
      navigate('/');
    }
    if (!isLoggedIn && location.pathname != '/login') {
      navigate('/login');
    }
  }, [isLoggedIn]);

  return (
    <>
      {isLoggedIn ? (
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <AppBar
            position="fixed"
            sx={{
              width: `calc(100% - ${drawerWidth}px)`,
              ml: `${drawerWidth}px`,
            }}
          >
            <Toolbar>
              <Typography variant="h6" noWrap component="div">
                KANON CMS
              </Typography>
            </Toolbar>
          </AppBar>
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'border-box',
              },
            }}
            variant="permanent"
            anchor="left"
          >
            <Typography variant="h6" align="center" sx={{ padding: '16px' }}>
              Dashboard
            </Typography>

            <Divider />
            <List>
              {['Users', 'Projects', 'Documents', 'Sign Out'].map(
                (text, index) => (
                  <ListItem key={text}>
                    <ListItemButton
                      onClick={() =>
                        index === 0
                          ? navigate('/users')
                          : index === 1
                          ? navigate('/projects')
                          : index === 2
                          ? navigate('/documents')
                          : logout()
                      }
                    >
                      <ListItemText primary={text} />
                    </ListItemButton>
                  </ListItem>
                )
              )}
            </List>
          </Drawer>
          <Box
            component="main"
            sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
          >
            <Toolbar />
            {children}
          </Box>
        </Box>
      ) : (
        <React.Fragment>{children}</React.Fragment>
      )}
    </>
  );
};

export default Layout;
